import axios from "axios";

const instance = axios.create({

    baseURL: process.env.REACT_APP_API_URL,                                                            
    headers : {
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin" : "*"
    }
});

function getAuthorization() {
    let Authorization = sessionStorage.getItem('Authorization')
    if(Authorization === null) return ''
    return Authorization
}

instance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = 'Bearer ' +getAuthorization()
        return config
    }
)

export default instance
